<template>
  <div>
    <v-container grid-list-lg class="mt-7">
      <v-layout row wrap>
        <v-flex xs12 sm6>
          <v-skeleton-loader
            type="image"
            v-if="loadingCategories"
          ></v-skeleton-loader>
          <v-img :src="getProductImage(imageData)" v-else></v-img>
        </v-flex>
        <v-flex xs12 sm6>
          <product-detail />
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import ProductDetail from "../components/Product/ProductDetail";
import { getProductImage } from "@/Helper";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProductPage",
  metaInfo() {
    return {
      title: this.currentProduct ? this.currentProduct.name : "",
    };
  },
  mixins: [getProductImage],
  components: {
    ProductDetail,
  },
  computed: {
    ...mapGetters(["allProducts", "currentProduct", "loadingCategories"]),
    imageData() {
      if (this.currentProduct) {
        return this.currentProduct.imageUrl;
      }

      return null;
    },
  },
  mounted() {
    let productAlias = this.$router.currentRoute.params;
    if (typeof productAlias.alias !== "undefined") {
      this.changeProduct({ langChange: false });
    }
  },
  methods: {
    ...mapActions(["changeProduct"]),
  },
};
</script>

