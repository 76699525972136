<template>
  <v-container fluid class="page-bg py-5">
    <v-container class="text-center">
      <img :src="getProductImage(imageUrl)" class="category-image" />
      <h1 class="black--text main-title mb-4">{{ title }}</h1>
      <div class="description" v-if="description" v-html="description"></div>
      <div v-if="showCategories">
        <v-btn
          @click="$router.push('/category/' + category.alias)"
          text
          :color="getButtonColor(category.alias)"
          v-for="category in allCategories"
          :key="category.id"
          >{{ category.name }}</v-btn
        >
      </div>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getProductImage } from "@/Helper";

export default {
  name: "CategoryHeader",
  props: ["showCategories"],
  mixins: [getProductImage],
  computed: {
    ...mapGetters(["currentCategory", "allCategories"]),
    title() {
      if (this.currentCategory) {
        return this.currentCategory.name;
      }

      return "Obchod";
    },
    description() {
      if (this.currentCategory) {
        return this.currentCategory.description;
      }

      return null;
    },
    imageUrl() {
      if (this.currentCategory) {
        return this.currentCategory.imageUrl;
      }

      return null;
    },
    activeItem() {
      if (this.currentCategory) {
        return this.currentCategory.alias;
      }

      return null;
    },
  },

  methods: {
    ...mapActions(["changeCategory"]),
    getButtonColor(alias) {
      if (alias === this.activeItem) {
        return "primary";
      }

      return "black";
    },
  },
};
</script>

<style lang="scss">
.page-bg {
  background-size: cover;
  background-position: center center;
  .main-title {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 600;
  }
  .v-btn__content {
    font-weight: bold;
  }
  .category-image {
    max-width: 100px;
    height: 52px;
  }
}
</style>
