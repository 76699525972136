import i18n from '../i18n.js'

class ValidationRules {

    static getRules() {
        return {
            required: value => !!value || i18n.t('cart.requiredField'),
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || i18n.t('cart.invalidEmail')
            },
            allow: value => {return true} // eslint-disable-line
        };
    }
}

export default ValidationRules;
