<template>
  <div>
    <v-container fluid class="main-title-heading">
      <v-row>
        <v-col cols="12" class="text-center">
          <h2 class="display-1 font-weight-bold text-uppercase py-4 mb-0">
            {{ $t("category.electronicTickets") }}
          </h2>
        </v-col>
      </v-row>
    </v-container>
    <category-header :show-categories="false" />
    <products />
  </div>
</template>

<script>
import CategoryHeader from "../components/Category/CategoryHeader";
import Products from "../components/Category/Products";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CategoryPage",
  metaInfo() {
    return {
      title: this.currentCategory ? this.currentCategory.name : "",
    };
  },
  watch: {
    "$route.params.alias": function () {
      this.changeCategory({ categoryId: null, langChange: false });
    },
    loadingCategories: function (newValue) {
      if (!newValue) {
        if (this.$router.currentRoute.name === "Homepage") {
          this.changeCategory({
            categoryId: process.env.VUE_APP_MAIN_CATEGORY,
            langChange: false,
          });
        } else {
          this.changeCategory({ categoryId: null, langChange: false });
        }
      }
    },
  },
  mounted() {
    if (this.$router.currentRoute.name === "Homepage") {
      this.changeCategory({
        categoryId: process.env.VUE_APP_MAIN_CATEGORY,
        langChange: false,
      });
    } else {
      this.changeCategory({ categoryId: null, langChange: false });
    }
  },
  components: {
    CategoryHeader,
    Products,
  },
  computed: {
    ...mapGetters(["allProducts", "currentCategory", "loadingCategories"]),
  },
  methods: {
    ...mapActions(["changeCategory"]),
  },
};
</script>

<style>
.main-title-heading {
  background-color: #f3f2ee;
}
</style>
