<template>
  <v-container grid-list-lg>
    <v-layout row wrap v-if="loadingCategories">
      <v-flex xs12 sm6 lg3 v-for="n in 3" :key="n">
        <v-skeleton-loader type="card"></v-skeleton-loader>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="category-products mb-10 justify-center">
      <v-flex xs12 sm6 md3 lg3 v-for="product in allProducts" :key="product.id">
        <v-card flat class="text-center transparent">
          <v-img
            :src="require('../../assets/vstupenka_bez_ceny.svg')"
            max-width="100"
            height="60"
            class="mx-auto align-center price"
          >
            <strong class="primary--text">{{
              $t("homepage.currency", { price: product.amountWithVat })
            }}</strong>
          </v-img>
          <h3
            class="product-title mb-2"
            @click="$router.push('/product/' + product.alias)"
          >
            {{ product.name }}
          </h3>
          <v-img
            class="cursor-pointer"
            :src="getProductImage(product.imageUrl)"
            @click="$router.push('/product/' + product.alias)"
          >
          </v-img>
          <v-card-text class="pt-0 px-0 text-center cart-button-wrapper">
            <v-btn
              small
              color="primary"
              class="cart-button text-lowercase"
              depressed
              @click="addToCart({ product: product, qty: 1 })"
            >
              {{ $t("cart.addToCart") }}
              <img
                class="cart-image"
                :src="require('../../assets/shoping_bag.svg')"
              />
            </v-btn>
          </v-card-text>
          <v-card-text class="description">
            <strong v-html="product.description"></strong>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { getProductImage } from "@/Helper";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Products",
  mixins: [getProductImage],
  computed: {
    ...mapGetters(["allProducts", "loadingCategories"]),
  },
  watch: {
    allProducts: function (newValue) {
      if (newValue) {
        if (this.$gtm.enabled()) {
          let products = [];

          for (let i in this.allProducts) {
            products.push({
              id: this.allProducts[i].productId,
              name: this.allProducts[i].name,
              price: this.allProducts[i].amountWithVat,
              category: this.allProducts[i].productCategoryName,
              list: "Home Page",
              position: i + 1,
            });
          }
          this.$gtm.trackEvent({
            event: "productImpression", // Event type [default = 'interaction'] (Optional)
            category: "Ecommerce",
            ecommerce: {
              impressions: products,
            },
          });
        }
      }
    },
  },
  methods: {
    ...mapActions(["addToCart"]),
  },
};
</script>

<style lang="scss">
.category-products {
  .cart-button-wrapper {
    margin-top: -10px;
  }
  .cart-button {
    text-transform: initial;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .cart-image {
    width: 30px;
  }
  .price {
    strong {
      display: inline-flex;
      margin-top: 5px;
      font-size: 13px;
    }
  }
  .product-title {
    text-transform: uppercase;
    color: #2f1917;
  }
  .description {
    p {
      margin-bottom: 0 !important;
    }
  }
}
</style>
