<template>
  <v-container class="footer-address white--text">
    <v-row>
      <v-col cols="12" sm="6">
        <p class="mb-0">Zoo Liberec, příspěvková organizace</p>
        <p class="mb-0">Lidové sady 425/1</p>
        <p class="mb-0">Liberec</p>
        <p class="mb-0">46001</p>
      </v-col>
      <v-col cols="12" sm="6" class="pa-0">
        <v-img
          class="ml-auto mr-sm-0 mr-auto"
          max-width="180"
          :src="require('../../assets/skupina_white.svg')"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FooterAddress",
};
</script>

<style lang="scss">
.footer-address {
  p {
    font-weight: 700;
  }
}
</style>
